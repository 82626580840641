import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {

    return (
    <div className={styles.container}>
        <footer className={styles.footer}>
            <p>RS</p>
            <p>info@looty.ch</p>
            <p>LOOTY © 2024</p>
        </footer>
    </div>
    );
}

export default Footer