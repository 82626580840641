import React, {useEffect, useState} from "react";
import useSociety from "../../../hooks/useSociety";
import useDataPut from "../../../hooks/useDataPut";
import {SocietyDTO} from "../../../context/SocietyProvider";

const SocietyAdminGeneral = () => {

    const {society, setSociety} = useSociety();
    const [isSelling, setIsSelling] = useState<boolean>(society.societyAdminDetails?.selling || false);

    const {data, error, isLoading, putData} = useDataPut<SocietyDTO>('', {}, true, false);

    const updateSociety = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await putData(`/societies/${society.id}/admin-details`, {societyId: society.id, selling: isSelling});
    }

    useEffect(() => {
        data && setSociety(data)
    }, [data]);

    return (
        <div style={{border: '1px solid black'}}>
            <h3>Infos générales</h3>
            <form onSubmit={(e) => updateSociety(e)}>
                <label>
                    <input onChange={(e) => setIsSelling(!isSelling)} checked={isSelling} type="checkbox" />
                    Vente d'articles
                </label>
                <br></br>
                <button type="submit">Mettre à jour les infos générales de la société</button>
            </form>
            <br></br>
        </div>
    );
}

export default SocietyAdminGeneral