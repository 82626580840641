import React from "react";
import useSociety from "../../../hooks/useSociety";

const SocietyHomepageContact = () => {

    const {society, setSociety} = useSociety();

    return (
        <div style={{border: '1px solid black'}}>
            <h3>Infos générales</h3>
            <div>
                Nom de la société: {society.name}
            </div>
        </div>
    );
}

export default SocietyHomepageContact