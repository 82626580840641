import React, {useState} from "react";
import useSociety from "../../../../hooks/useSociety";
import useDataPost from "../../../../hooks/useDataPost";
import {SocietyDTO} from "../../../../context/SocietyProvider";

const SocietyAdminArticlesAdd = () => {

    const {society} = useSociety();

    const [productName, setProductName] = useState<string>('');
    const [productPrice, setProductPrice] = useState<number>(0);
    const [image, setImage] = useState<File | null>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const {postData} = useDataPost<SocietyDTO>(`/societies/${society.id}/products`, {}, true, false, true);

    const addProduct = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData: any = new FormData();
        formData.append('name', productName)
        formData.append('price', productPrice)
        formData.append('image', image)
        formData.append('visible', isVisible)

        await postData(undefined, formData);
        window.location.reload();
    };


    return (
        <div style={{border: '1px solid black'}}>
            <h4>Ajouter un article</h4>
            <form onSubmit={addProduct}>
                <input
                    type="text"
                    placeholder="Nom"
                    onChange={(event) => setProductName(event.target.value)}
                    required
                />
                <input
                    type="number"
                    placeholder="Prix"
                    onChange={(event) => setProductPrice(event.target.value as unknown as number)}
                    required
                />
                <label>
                    <input onChange={(e) => setIsVisible(!isVisible)} checked={isVisible} type="checkbox" />
                    Publié ?
                </label>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                        event.target.files && event.target.files.length > 0 && setImage(event.target.files[0])
                    }}
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default SocietyAdminArticlesAdd