import React, {useEffect, useState} from "react";
import useBasket from "../../../hooks/useBasket";
import useDataFetch from "../../../hooks/useDataFetch";
import {
    ProductDTO
} from "../../../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useSociety from "../../../hooks/useSociety";
import useDataPost from "../../../hooks/useDataPost";
import DatatransLightBox from "./datatransLightBox/DatatransLightBox";

interface DatatransTransactionResponseDTO {
    transactionId?: string;
}

const SocietyCart = () => {
    const {society} = useSociety();
    const {basket, addToBasket, reduceFromBasket} = useBasket();

    const [transactionId, setTransactionId] = useState<string>('');
    const [paymentStatus, setPaymentStatus] = useState<string>('');

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        street: '',
        streetNumber: '',
        city: '',
        zip: '',
        email: '',
        phoneNumber: '',
    });

    const {data: products, fetchData} = useDataFetch<ProductDTO[]>('', false, false,);
    const {
        data: datatransTransactionResponse,
        postData
    } = useDataPost<DatatransTransactionResponseDTO>(`/datatrans/init-transaction`, {}, false, false);

    const initTransaction = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const basketData: any[] = []
        basket.forEach((v, k) => {
            basketData.push({
                productId: k,
                quantity: v
            })
        })

        postData(undefined, {
            societyId: society.id,
            basket: basketData,
            ...formData
        })
    }

    useEffect(() => {
        datatransTransactionResponse?.transactionId && setTransactionId(datatransTransactionResponse.transactionId)

        if (basket && society && !products) {
            const productsIds: number[] = Array.from(basket.keys());
            const parameter: string = '?&products=' + productsIds.join('&products=')

            fetchData(`/societies/${society.id}/products${parameter}`)
        }

        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');
        if (status && status !== paymentStatus) {
            setPaymentStatus(status || '');
            alert('Statut du paiement: ' + status)
        }

    }, [datatransTransactionResponse, society, basket, paymentStatus]);

    return (
        <div>
            {basket.size === 0 ?
                <div>
                    <p>Panier vide</p>
                </div> :
                <div>
                    <ul>
                        {products && products.map((product: ProductDTO, index: number) => (
                            <li key={index}>
                                Nom: {product.name} <br></br>
                                Prix: {product.price}CHF <br></br>
                                Quantité: {product.id ? basket.get(product.id) ?? 0 : 0} <br></br>
                                <button onClick={() => addToBasket(product)}>Ajouter au panier</button>
                                <button onClick={() => reduceFromBasket(product)}>Décrémenter du panier</button>
                                <br></br>
                            </li>
                        ))}
                    </ul>

                    <form onSubmit={initTransaction}>
                        <input
                            type="text"
                            placeholder="Prénom"
                            onChange={(event) => setFormData({...formData, firstname: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Nom"
                            onChange={(event) => setFormData({...formData, lastname: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Email"
                            onChange={(event) => setFormData({...formData, email: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="N° de téléphone"
                            onChange={(event) => setFormData({...formData, phoneNumber: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Localité"
                            onChange={(event) => setFormData({...formData, city: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="NPA"
                            onChange={(event) => setFormData({...formData, zip: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Rue"
                            onChange={(event) => setFormData({...formData, street: event.target.value})}
                            required
                        />
                        <input
                            type="text"
                            placeholder="N° de rue"
                            onChange={(event) => setFormData({...formData, streetNumber: event.target.value})}
                            required
                        />
                        <button type="submit">Acheter</button>
                    </form>

                    {transactionId
                        && <DatatransLightBox transactionId={transactionId} setTransactionId={setTransactionId}/>
                    }
                </div>
            }
        </div>
    )
}

export default SocietyCart