import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useSociety from "../../hooks/useSociety";

const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();
    const {society} = useSociety();

    return (
        (auth?.token && auth?.societyId === society.id)
            ? <Outlet/>
            : <Navigate to="/unauthorized" state={{from: location}} replace/>
    )
}

export default RequireAuth;