import React, {useEffect} from "react";
import SocietiesList from "../../components/societiesList/SocietiesList";
import Contact from "../../components/contact/Contact";
import GlobalSearch from "../../components/globalSearch/GlobalSearch";
import About from "../../components/about/About";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";

const Homepage = () => {

    const {societiesSection, aboutSection, contactSection, setNavItems} = useSectionsRefs();

    useEffect(() => {
        const navItems = [
            {title: 'Sociétés', scrollRef: societiesSection},
            {title: 'À Propos', scrollRef: aboutSection},
            {title: 'Contact', scrollRef: contactSection},
        ];
        setNavItems(navItems)
    }, [societiesSection, aboutSection, contactSection, setNavItems]);

    return (
        <div>
            <GlobalSearch/>
            <SocietiesList/>
            <About/>
            <Contact/>
        </div>
    );
}

export default Homepage