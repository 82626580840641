import React from "react";
import SocietyAdminArticlesAdd from "./societyAdminArticlesAdd/SocietyAdminArticlesAdd";
import SocietyAdminArticlesList from "./societyAdminArticlesList/SocietyAdminArticlesList";
import SocietyAdminArticlesUpdate from "./societyAdminArticlesUpdate/SocietyAdminArticlesUpdate";

const SocietyAdminArticles = () => {

    return (
        <div style={{border: '1px solid black'}}>
            <SocietyAdminArticlesList/>
            <SocietyAdminArticlesAdd/>
            <SocietyAdminArticlesUpdate/>

        </div>
    );
}

export default SocietyAdminArticles