import {useEffect, useState} from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import axios from "../api/axios";

const useDataPost = <T>(endpoint: string = '', body: any = undefined, isPrivate: boolean = false, fireAutomatically: boolean = false, isFormData: boolean = false) => {
    const [data, setData] = useState<null | T>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const customAxios = isPrivate ? axiosPrivate : axios;

    const postData = async (endpointOverride: string | undefined = undefined, bodyOverride: any = undefined) => {
        setLoading(true);
        const endpointToUse: string = endpointOverride ? endpointOverride : endpoint;
        const bodyToUse: any = bodyOverride ? bodyOverride : body;
        try {
            const response = await customAxios.post<T>(
                endpointToUse,
                bodyToUse,
                {headers: {'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'}}
            );
            setData(response.data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    const postDataPromise = async (endpoint: string, body: any) => {
        return await customAxios.post<T>(
            endpoint,
            body,
            {headers: {'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'}}
        );
    }

    useEffect(() => {
        if (fireAutomatically) {
            postData(endpoint, body);
        }
    }, [fireAutomatically, isPrivate, endpoint]);

    return {data, error, isLoading, postData, postDataPromise};
}

export default useDataPost;