import {useEffect, useState} from "react";
import axios from '../api/axios';
import useAxiosPrivate from "./useAxiosPrivate";

const useDataFetch = <T>(endpoint: string, isPrivate: boolean, fireAutomatically: boolean = true) => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const customAxios = isPrivate ? axiosPrivate : axios;

    const fetchData = async (endpointOverride: string | undefined = undefined) => {
        setLoading(true);
        const endpointToUse: string = endpointOverride ? endpointOverride : endpoint;
        try {
            const response = await customAxios.get<T>(endpointToUse);
            setData(response.data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    const fetchDataPromise = async (endpoint: string) => {
        return await customAxios.get<T>(endpoint);
    }

    useEffect(() => {
        if (fireAutomatically) {
            fetchData();
        }
    }, [fireAutomatically, isPrivate, endpoint]);

    return {data, error, isLoading, fetchData, fetchDataPromise};

}

export default useDataFetch;