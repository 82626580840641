import {Outlet, useOutletContext} from "react-router-dom";
import React, {MutableRefObject, useRef, useState} from "react";
import Footer from "../footer/Footer";
import ResponsiveAppBar from "../appBar/AppBar";

export interface NavItemAppBar {
    title: string,
    scrollRef?: MutableRefObject<HTMLDivElement | null>,
}

export interface ContextType {
    societiesSection: MutableRefObject<HTMLDivElement | null>;
    aboutSection: MutableRefObject<HTMLDivElement | null>;
    contactSection: MutableRefObject<HTMLDivElement | null>;
    servicesSection: MutableRefObject<HTMLDivElement | null>;
    ratesSection: MutableRefObject<HTMLDivElement | null>;
    navItems: NavItemAppBar[];
    setNavItems: (navItems: NavItemAppBar[]) => void;
    setDisplayRegisterSocietyButton: (displayRegisterSocietyButton: boolean) => void;
}

const MainWrapper = () => {

    const [navItems, setNavItems] = useState<NavItemAppBar[]>([]);
    const [displayRegisterSocietyButton, setDisplayRegisterSocietyButton] = useState<boolean>(true);

    const societiesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const aboutSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const contactSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    const servicesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const ratesSection: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

    return (
        <>
            <ResponsiveAppBar
                navItems={navItems}
                displayRegisterSocietyButton={displayRegisterSocietyButton}
            />
            <Outlet context={{societiesSection, aboutSection, contactSection, servicesSection, ratesSection, setNavItems, setDisplayRegisterSocietyButton}}/>
            <Footer/>
        </>
    )
}

export function useSectionsRefs() {
    return useOutletContext<ContextType>();
}

export default MainWrapper;