import useAuth from "./useAuth";
import axios from "../api/axios";
import {AuthenticationResponse} from "./useLogin";

const useLogout = () => {

    const {setAuth} = useAuth();

    const logout = () => {
        axios.post<AuthenticationResponse>(`auth/logout`, {}, {withCredentials: true}).then(() => {
            setAuth({token: '', societyId: 0});
        })
    }

    return logout;

}

export default useLogout;