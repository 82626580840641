import React, {useEffect, useState} from "react";
import useSociety from "../../../hooks/useSociety";
import useDataPut from "../../../hooks/useDataPut";
import {SocietyDTO} from "../../../context/SocietyProvider";

const SocietyAdminContact = () => {

    const {society, setSociety} = useSociety();
    const [address, setAddress] = useState<string>(society.societyContactDetails?.address || '');

    const {data, error, isLoading, putData} = useDataPut<SocietyDTO>('', {}, true, false);

    const updateSociety = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await putData(`/societies/${society.id}/contact-details`, {societyId: society.id, address: address});
    }

    useEffect(() => {
        data && setSociety(data)
    }, [data]);

    return (
        <div style={{border: '1px solid black'}}>
            <h3>Contact</h3>
            <form onSubmit={(e) => updateSociety(e)}>
                <input type="text" placeholder="Adresse"
                       onChange={(e) => setAddress(e.target.value)} required/>
                <br></br>
                <button>Mettre à jour l'adresse de la société</button>
            </form>
        </div>
    );
}

export default SocietyAdminContact