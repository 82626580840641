import React from "react";
import styles from "./LineOfServices.module.css";
import {Grid2} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface serviceDescription {
    title: string,
    image: string,
    text: string,
}

interface LineOfServiceProps {
    servicesDescriptions: serviceDescription[]
}

const LineOfServices = ({servicesDescriptions}: LineOfServiceProps) => {

    return (
        <Grid2 container spacing={8}>
            {servicesDescriptions.map((service) => (
                <Grid2 size={{md: 6, lg: 3}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                        <img className={styles.serviceImg} src={service.image} alt="tickets"/>
                        <Typography
                            sx={{
                                color: '#543B26',
                                mt: 4
                            }}
                            variant={"firstVariant"}
                            fontSize={30}
                        >
                            {service.title}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#543B26',
                                textAlign: 'left'
                            }}
                            variant={"firstVariant"}
                            component="h3"
                            fontWeight={300}
                        >
                            {service.text}
                        </Typography>
                    </Box>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default LineOfServices