import React from "react";
import styles from "./GlobalSearch.module.css";
import {TextField} from "@mui/material";

const GlobalSearch = () => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <img className={styles.logoImg} src={'/looty-logo.png'} alt="looty logo"/>
                <p>
                    Découvre des sociétés près de chez toi, commande directement leurs produits en ligne, informe-toi sur
                    leurs manifestations... et bien plus encore !
                </p>

                <TextField
                    type="text"
                    variant='filled'
                    label="Produit, société, manifestation, ..."
                    sx={{
                        width: 400,
                        borderRadius: 10,
                    }}
                />

                {/*<input style={{width: '200px'}} type="text" placeholder="Produit, société, manifestation, ..."/>*/}
            </div>
        </div>
    );
}

export default GlobalSearch