import React from "react";

const SocietyNotFound = () => {

    return (
        <div>
            <p>Cette société n'existe pas.</p>
        </div>
    );
}

export default SocietyNotFound