import React from "react";
import styles from "./SocietiesList.module.css";
import useDataFetch from "../../hooks/useDataFetch";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";

const SocietiesList = () => {

    const { societiesSection } = useSectionsRefs();

    const {data: subdomainsAvailable} = useDataFetch(
        `/societies`, false
    );

    return (
        <div className={styles.container} ref={societiesSection}>
            <div className={styles.content}>
                <h2>SOCIÉTÉS</h2>
                <p>
                    Qu’elles vendent des produits artisanaux, offrent leurs services sur rendez-vous, organisent des
                    manifestations, ou veuillent tout simplement se faire connaître, les sociétés de ta région sont sur
                    Looty. N’attends plus pour les découvrir.
                </p>

                {subdomainsAvailable && subdomainsAvailable.length === 0 ? (
                    <p>Aucune société n'est encore inscrite, soyez la première !</p>
                ) : (
                    <ul>
                        {subdomainsAvailable?.map((society: any) =>
                            <li key={society}>
                                <a href={`http://${society}.looty.ch`}>{society}</a>
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default SocietiesList