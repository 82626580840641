import React from "react";
import useSociety from "../../../hooks/useSociety";
import SocietyHomepageContact from "../../../components/societyHomepage/societyHomepageContact/SocietyHomepageContact";
import SocietyHomepageGeneral from "../../../components/societyHomepage/societyHomepageGeneral/SocietyHomepageGeneral";
import SocietyHomepageArticles
    from "../../../components/societyHomepage/societyHomepageArticles/SocietyHomepageArticles";

const SocietyHomepage = () => {

    const {society} = useSociety();

    return (
        (society && society.enabled && society.visible) ? (
            <>
                <SocietyHomepageGeneral/>
                <SocietyHomepageContact/>
                {society.societyAdminDetails?.selling && <SocietyHomepageArticles/>}
            </>
        ) : (
            <div>
                <p>Cette société n'est pas accessible actuellement.</p>
                <p>Si vous en êtes le propriétéaire, veuillez vous connecter pour la gérer.</p>
            </div>
        )
    );
}

export default SocietyHomepage