import React from "react";
import {Button, Card, CardContent, Grid2} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

interface RateDescription {
    title: string,
    servicesIncluded: string[],
}

interface LineOfRatesProps {
    ratesDescriptions: RateDescription[]
}

const rates = [
    {
        title: 'Basic',
        servicesIncluded: ['Virtual Credit Cards', 'Financial Analytics', 'Checking Account', 'API Integration'],
    },
    {
        title: 'Premium',
        servicesIncluded: ['Virtual Credit Cards', 'Financial Analytics', 'Checking Account', 'API Integration'],
    },
    {
        title: 'Ultra',
        servicesIncluded: ['Virtual Credit Cards', 'Financial Analytics', 'Checking Account', 'API Integration'],
    },
    {
        title: 'Custom',
        servicesIncluded: ['Virtual Credit Cards', 'Financial Analytics', 'Checking Account', 'API Integration'],
    }
]

const LineOfRates = () => {

    return (
        <Grid2 container spacing={4}>
            {rates.map((rate: RateDescription) => (
                <Grid2 size={{xs: 12, sm: 12, md: 6, lg: 3}}>
                    <Card sx={{borderRadius: 5}}>
                        <CardContent>
                            <Typography
                                variant="thirdVariant"
                                fontSize={35}
                                textAlign={"left"}
                                sx={{}}
                            >
                                {rate.title}
                            </Typography>
                            <Divider sx={{mt: 1, mb: 1}}/>
                            <Grid2>
                                {rate.servicesIncluded.map((serviceIncluded, index) => (
                                    <ListItem key={index} sx={{mb: -2, pl: 0}}>
                                        <ListItemText primary={serviceIncluded} primaryTypographyProps={{fontSize: '18px'}}/>
                                    </ListItem>
                                ))}
                            </Grid2>
                            <Divider sx={{mt: 3, mb: 1}}/>
                            <Grid2>
                                {rate.servicesIncluded.map((serviceIncluded, index) => (
                                    <ListItem key={index} sx={{mb: -2, pl: 0}}>
                                        <ListItemText primary={serviceIncluded} primaryTypographyProps={{fontSize: '18px'}}/>
                                    </ListItem>
                                ))}
                            </Grid2>
                            <Divider sx={{mt: 3, mb: 3}}/>
                            <Grid2>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={25}
                                        textAlign={"left"}
                                    >
                                        3.990CHF
                                    </Typography>
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        sx={{ml: 0.5}}
                                    >
                                        /mois
                                    </Typography>
                                </Box>
                                <Button>
                                    asdf
                                </Button>
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default LineOfRates