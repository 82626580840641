import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import CustomAvatar from "./avatar/Avatar";
import {useNavigate} from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Typography from "@mui/material/Typography";
import LoginModal from "../loginModal/LoginModal";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import styles from "./AppBar.module.css";
import {NavItemAppBar} from "../mainWrapper/MainWrapper";

interface DrawerAppBarProps {
    window?: () => Window;
    navItems: NavItemAppBar[];
    displayRegisterSocietyButton?: boolean;
}

const drawerWidth = 240;

const DrawerAppBar = ({
                          window,
                          navItems,
                          displayRegisterSocietyButton = true,
                      }: DrawerAppBarProps) => {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const {auth} = useAuth();
    const logout = useLogout();

    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const handleLogout = () => {
        logout();
        // eslint-disable-next-line no-restricted-globals
        window !== undefined && location.reload();
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{p: 1}}>
            <Box
                component="img"
                sx={{display: {md: 'none'}, width: 80}}
                src={'/looty-logo.png'}
                alt="looty logo"
            />
            <Divider/>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.title} disablePadding>
                        <ListItemButton
                            sx={{p: 0}}
                            onClick={() => {
                                item.scrollRef?.current?.scrollIntoView({
                                    behavior: 'smooth'
                                })
                            }}
                        >
                            <ListItemText primary={item.title}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            {auth.token ?
                <Button
                    sx={{mt: 1, mb: 1}}
                    variant="secondVariant"
                    onClick={() => handleLogout()}
                >
                    Se déconnecter
                </Button>
                : <>
                    {displayRegisterSocietyButton && (
                        <Button
                            sx={{mt: 1}}
                            variant="thirdVariant"
                            onClick={() => navigate('/info')}
                        >
                            Inscrire ma société
                        </Button>
                    )}
                    <Button
                        sx={{mt: 1, mb: 1}}
                        variant="secondVariant"
                        onClick={() => setOpen(true)}
                    >
                        Se connecter
                    </Button>
                </>}
            <Divider/>
            <IconButton
                aria-label="Instagram"
                variant="firstVariant"
            >
                <InstagramIcon/>
            </IconButton>
            <IconButton
                aria-label="LinkedIn"
                variant="firstVariant"
            >
                <LinkedInIcon/>
            </IconButton>
            <Divider/>
            <Typography sx={{height: 20}} variant={"firstVariant"}>
                info@looty.ch
            </Typography>
            <Typography sx={{height: 20}} variant={"firstVariant"}>
                Looty © 2024
            </Typography>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    <AppBar component="nav">
                        <Toolbar
                            sx={{
                                width: "100%",
                                maxWidth: 1200,
                                mx: "auto"
                            }}
                        >
                            <IconButton
                                variant="firstVariant"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{mr: 2, display: {md: 'none'}}}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Box
                                component="img"
                                sx={{display: {xs: 'none', sm: 'none', md: 'block'}, width: 120}}
                                src={'/looty-logo.png'}
                                alt="looty logo"
                            />
                            <Box
                                sx={{display: {sm: 'block', md: 'none'}, flexGrow: 1}}
                            >
                                <Box
                                    component="img"
                                    sx={{width: 120}}
                                    src={'/looty-logo.png'}
                                    alt="looty logo"
                                >
                                </Box>
                            </Box>
                            <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}} flexGrow={1}>
                                {navItems.map((item) => (
                                    <Button
                                        key={item.title}
                                        sx={{color: '#000000'}}
                                        onClick={() => {
                                            item.scrollRef?.current?.scrollIntoView({
                                                behavior: 'smooth'
                                            })
                                        }
                                        }
                                    >
                                        {item.title}
                                    </Button>
                                ))}
                            </Box>
                            {auth.token ? <CustomAvatar/> : (
                                <>
                                    {displayRegisterSocietyButton && (
                                        <Button
                                            sx={{
                                                display: {xs: 'none', sm: 'none', md: 'block'}
                                            }}
                                            variant="thirdVariant"
                                            onClick={() => navigate('/info')}
                                        >
                                            Inscrire ma société
                                        </Button>)}

                                    <Button
                                        sx={{
                                            ml: 1,
                                            display: {xs: 'none', sm: 'none', md: 'block'}
                                        }}
                                        variant="secondVariant"
                                        onClick={() => setOpen(true)}
                                    >
                                        Se connecter
                                    </Button>
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                    <nav>
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {sm: 'block', md: 'none'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </nav>
                    <Toolbar/>
                </Box>
                <LoginModal open={open} setOpen={setOpen}/>
            </div>
        </div>
    );
}

export default DrawerAppBar;
