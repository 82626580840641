import React from "react";
import useSociety from "../../../hooks/useSociety";
import SocietyAdminGeneral from "../../../components/societyAdmin/societyAdminGeneral/SocietyAdminGeneral";
import SocietyAdminContact from "../../../components/societyAdmin/societyAdminContact/SocietyAdminContact";
import SocietyAdminArticles from "../../../components/societyAdmin/societyAdminArticles/SocietyAdminArticles";

const SocietyAdmin = () => {

    const {society, setSociety} = useSociety();

    return (
        <div>
            <h1>Page d'admin de {society.name}</h1>
            <SocietyAdminGeneral/>
            <SocietyAdminContact/>
            <SocietyAdminArticles/>
            <br></br>
        </div>
    );
}

export default SocietyAdmin