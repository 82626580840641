import {createContext, Dispatch, ReactNode, SetStateAction, useState} from "react";

interface Auth {
    token: string,
    societyId: number,
}

interface AuthContextInterface {
    auth: Auth
    setAuth: Dispatch<SetStateAction<Auth>>
}

const AuthContext = createContext<AuthContextInterface>({
    auth: {
        token: '',
        societyId: 0,
    },
    setAuth: () => {
    }
});

interface AuthProviderProps {
    children: ReactNode
}

export const AuthProvider = ({children}: AuthProviderProps) => {
    const [auth, setAuth] = useState<Auth>({token: '', societyId: 0});

    return (
        <AuthContext.Provider value={{
            auth,
            setAuth
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;