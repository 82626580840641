import React, {useState} from "react";
import styles from "./Register.module.css";

import useDataPost from "../../hooks/useDataPost";

const Register = () => {

    const [emailRegistration, setEmailRegistration] = useState("");
    const [passwordRegistration, setPasswordRegistration] = useState("");
    const [societyNameRegistration, setSocietyNameRegistration] = useState("");
    const [subdomainRegistration, setSubdomainRegistration] = useState("");
    const [isSelling, setIsSelling] = useState<boolean>(false);


    const {postData} = useDataPost("/auth/register", {
        email: emailRegistration,
        password: passwordRegistration,
        societyName: societyNameRegistration,
        societySubdomain: subdomainRegistration,
        selling: isSelling,
    }, false);

    const register = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postData();
    }

    return (
        <div className={styles.container}>
            <h1>Page d'inscription</h1>
            <form onSubmit={(e) => register(e)}>
                <input type="email" placeholder="Email"
                       onChange={(e) => setEmailRegistration(e.target.value)} required/>
                <input type="text" placeholder="Society name"
                       onChange={(e) => setSocietyNameRegistration(e.target.value)} required/>
                <input type="text" placeholder="Subdomain"
                       onChange={(e) => setSubdomainRegistration(e.target.value)} required/>
                <label>
                    <input onChange={(e) => setIsSelling(!isSelling)} checked={isSelling} type="checkbox" />
                    Vente d'articles
                </label>
                <input type="password" placeholder="Mot de passe"
                       onChange={(e) => setPasswordRegistration(e.target.value)} required/>
                <button>S'inscrire</button>
            </form>
            <br></br>
        </div>
    );
}

export default Register