import {createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import {
    ProductDTO
} from "../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useSociety from "../hooks/useSociety";

const BASKET_KEY_SUFFIX = "_basket"; // Key for local storage

interface BasketContextInterface {
    basket: Map<number, number>
    setBasket: Dispatch<SetStateAction<Map<number, number>>>
    addToBasket: (product: ProductDTO) => void
    reduceFromBasket: (product: ProductDTO) => void
}

const BasketContext = createContext<BasketContextInterface>({
    basket: new Map(),
    setBasket: () => {
    },
    addToBasket: () => {
    },
    reduceFromBasket: () => {
    }
});

interface BasketProviderProps {
    children: ReactNode
}

export const BasketProvider = ({children}: BasketProviderProps) => {
    const {society} = useSociety();

    const [localStorageBasketKey, setLocalStorageBasketKey] = useState<string>('');
    const [basket, setBasket] = useState<Map<number, number>>(new Map());
    const addToBasket = (product: ProductDTO) => {
        setBasket(prevMap => {
            const newMap = new Map(prevMap);
            if (product.id) {
                const currentValue = newMap.get(product.id);
                const newValue = currentValue ? currentValue + 1 : 1;
                newMap.set(product.id, newValue);
            }
            localStorage.setItem(localStorageBasketKey, JSON.stringify(Array.from(newMap.entries())));
            return newMap;
        });
    }

    const reduceFromBasket = (product: ProductDTO) => {
        setBasket(prevMap => {
            const newMap = new Map(prevMap);
            if (product.id) {
                const currentValue = newMap.get(product.id);
                if (currentValue === undefined || currentValue <= 0) {
                    return newMap;
                }

                const newValue = currentValue - 1;
                newMap.set(product.id, newValue);
                if (newValue === 0) {
                    newMap.delete(product.id);
                }
            }
            localStorage.setItem(localStorageBasketKey, JSON.stringify(Array.from(newMap.entries())));
            return newMap;
        });
    };

    useEffect(() => {
        if (society) {
            const value: string = society.name + BASKET_KEY_SUFFIX;
            society && setLocalStorageBasketKey(value);
            const serializedBasket = localStorage.getItem(value);
            if (serializedBasket) {
                setBasket(new Map(JSON.parse(serializedBasket)));
            }
        }
    }, [society]);

    return (
        <BasketContext.Provider value={{
            basket,
            setBasket,
            addToBasket,
            reduceFromBasket,
        }}>
            {children}
        </BasketContext.Provider>
    )
}

export default BasketContext;