import React from "react";
import {useSearchParams} from "react-router-dom";
import useDataFetch from "../../hooks/useDataFetch";

interface RegisterConfirmationResponse {
    uid: string,
    society: string,
    subDomain: string
}

const RegistrationConfirmation = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const confirmationUid = searchParams.get("confirmationUid")

    const {
        data,
        error,
        fetchData
    } = useDataFetch<RegisterConfirmationResponse>(`/auth/confirm?confirmationUid=${confirmationUid}`, false, !!confirmationUid)

    return (
        <div>
            <h1>Registration confirmation</h1>

            {data && <div>
                <p>
                    Vous pouvez dès maintenant accéder à l'espace d'administration de votre page Looty en vous connectant via le lien suivant:
                    <a href={`https://${data.subDomain}.looty.ch`}>{`https://${data.subDomain}.looty.ch/admin`}</a>
                </p>
            </div>}

            {!confirmationUid &&
                <div>
                    <p>Invalid parameter</p>
                </div>
            }

            {(!confirmationUid || (error && error.status === 404)) &&
                <div>
                    <p>This validation link is not correct</p>
                </div>
            }

            {(error && error.status === 410) &&
                <div>
                    <p>Registration already confirmed</p>
                </div>
            }
        </div>
    );
}

export default RegistrationConfirmation