import React from "react";
import styles from "./Rates.module.css";
import Typography from "@mui/material/Typography";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import LineOfServices from "../services/LineOfServices/LineOfServices";
import LineOfRates from "./LineOfRates/LineOfRates";

const Rates = () => {

    const { ratesSection } = useSectionsRefs();

    return (
        <div className={styles.container} ref={ratesSection}>
            <div className={styles.content}>
                <Typography
                    variant={"thirdVariant"}
                    fontSize={45}
                    fontWeight={400}
                >
                    TARIFS
                </Typography>

                <Typography
                    sx={{
                        mt: 2,
                        mb: 4
                    }}
                    variant={"thirdVariant"}
                    fontSize={25}
                    fontWeight={400}
                >
                    Différents plans vous sont proposés en fonction des besoins de votre société. Le passage de l’un à l’autre peut se faire à tout moment.
                </Typography>

                <LineOfRates/>
            </div>
        </div>
    );
}

export default Rates