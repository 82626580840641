import React, {useState} from "react";
import useSociety from "../../../../hooks/useSociety";
import {SocietyDTO} from "../../../../context/SocietyProvider";
import useDataPut from "../../../../hooks/useDataPut";

const SocietyAdminArticlesUpdate = () => {

    const {society} = useSociety();

    const [productId, setProductId] = useState<number>(0);
    const [productName, setProductName] = useState<string>('');
    const [productPrice, setProductPrice] = useState<number>(0);

    const {putData} = useDataPut<SocietyDTO>(`/societies/${society.id}/products/${productId}`, {}, true, false);

    const updateProduct = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await putData(undefined, {
            name: productName,
            price: productPrice,
        });
        window.location.reload();
    };


    return (
        <div style={{border: '1px solid black'}}>
            <h4>Mettre à jour un article</h4>
            <form onSubmit={updateProduct}>
                <input
                    type="number"
                    id="textInput"
                    placeholder="Id"
                    onChange={(event) => setProductId(event.target.value as unknown as number)}
                    required
                />
                <input
                    type="text"
                    id="textInput"
                    placeholder="Nom"
                    onChange={(event) => setProductName(event.target.value)}
                    required
                />
                <input
                    type="number"
                    id="textInput"
                    placeholder="Prix"
                    onChange={(event) => setProductPrice(event.target.value as unknown as number)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default SocietyAdminArticlesUpdate