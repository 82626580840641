import React from "react";
import useSociety from "../../../hooks/useSociety";
import useDataFetch from "../../../hooks/useDataFetch";
import {ProductDTO} from "../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useBasket from "../../../hooks/useBasket";

const SocietyHomepageArticles = () => {

    const {society} = useSociety();
    const {basket, addToBasket, reduceFromBasket} = useBasket();

    const {data: products} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, false, true);

    return (
        <div style={{border: '1px solid black'}}>
            <h3>Articles</h3>
            <ul>
                {products && products.filter((product: ProductDTO) => product.visible).map((product: ProductDTO, index: number) => (
                    <li style={{border: '1px solid black'}}
                        key={index}>
                        Id: {product.id} <br></br>
                        Nom: {product.name} <br></br>
                        Prix: {product.price}CHF <br></br>
                        Quantité: {product.id ? basket.get(product.id) ?? 0 : 0} <br></br>
                        <img style={{width: '200px'}} src={`https://looty.ch:8443/api/v1/societies/${society.id}/products/${product.id}/image`} alt="product"/>
                        <button onClick={() => addToBasket(product)}>Ajouter au panier</button>
                        <button onClick={() => reduceFromBasket(product)}>Décrémenter du panier</button>
                        <br></br>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SocietyHomepageArticles