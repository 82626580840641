import React, {useEffect} from "react";
import Contact from "../../components/contact/Contact";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import LaunchRegister from "../../components/societiesHomepageComponents/launchRegister/LaunchRegister";
import Services from "../../components/societiesHomepageComponents/services/Services";
import Rates from "../../components/societiesHomepageComponents/rates/Rates";

const SocietiesHomepage = () => {

    const {servicesSection, ratesSection, contactSection, setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    useEffect(() => {
        const navItems = [
            {title: 'Services', scrollRef: servicesSection},
            {title: 'Tarifs', scrollRef: ratesSection},
            {title: 'Contact', scrollRef: contactSection},
        ];
        setNavItems(navItems);
        setDisplayRegisterSocietyButton(false);
    }, [setDisplayRegisterSocietyButton, servicesSection, ratesSection, contactSection, setNavItems]);


    return (
        <div>
            <LaunchRegister/>
            <Services/>
            <Rates/>
            <Contact/>
        </div>
    );
}

export default SocietiesHomepage