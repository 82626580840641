import Lightbox from "react-datatrans-light-box";
import React from "react";

interface DatatransLightBoxProps {
    transactionId: string
    setTransactionId: (newTransactionId: '') => void
}

const DatatransLightBox = ({transactionId, setTransactionId}: DatatransLightBoxProps) => {

    const onLoaded = () => {

    }

    const onOpened = () => {

    }
    const onCancelled = () => {
        setTransactionId('');
    }

    const onError = () => {
        setTransactionId('');
        alert("error during transaction, please try again");
    }

    return (
        <div>
            {<Lightbox
                    transactionId={transactionId}
                    production={false}
                    onLoaded={() => onLoaded()}
                    onOpened={() => onOpened()}
                    onCancelled={() => onCancelled()}
                    onError={() => onError()}
                />
            }
        </div>
    )
}

export default DatatransLightBox