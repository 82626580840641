import React, {useState} from "react";
import styles from "./Contact.module.css";

import useDataPost from "../../hooks/useDataPost";
import {Button, Stack, TextField} from "@mui/material";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";

const Contact = () => {

    const { contactSection } = useSectionsRefs();

    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [society, setSociety] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const {postData} = useDataPost('', {});

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        postData('/contact', {
            firstname,
            lastname,
            society,
            email,
            phoneNumber,
            message
        });
    }

    return (
        <div className={styles.container} ref={contactSection}>
            <div className={styles.content}>
                <h2>Contact</h2>
                <p>
                    Une question ? Vous souhaitez une démo du produit ? Que vous soyez une société ou un client de la
                    plateforme, n’hésitez pas à nous contacter et nous reviendrons vers vous dans les 48 heures.
                </p>

                <form onSubmit={(e) => submit(e)}>
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                        <TextField
                            type="text"
                            variant='filled'
                            label="Prénom"
                            onChange={e => setFirstname(e.target.value)}
                            value={firstname}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            variant='filled'
                            label="Nom"
                            onChange={e => setLastname(e.target.value)}
                            value={lastname}
                            fullWidth
                            required
                        />
                    </Stack>
                    <TextField
                        type="text"
                        variant='filled'
                        label="Société"
                        onChange={e => setSociety(e.target.value)}
                        value={society}
                        fullWidth
                        sx={{marginBottom: 4}}
                    />
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                        <TextField
                            type="email"
                            variant='filled'
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            fullWidth
                            required
                        />
                        <TextField
                            type="text"
                            variant='filled'
                            label="N° téléphone"
                            onChange={e => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                            fullWidth
                            required
                        />
                    </Stack>
                    <TextField
                        type="text"
                        variant='filled'
                        label="Votre message"
                        onChange={e => setMessage(e.target.value)}
                        value={message}
                        fullWidth
                        required
                        multiline
                        sx={{marginBottom: 4}}
                        rows={6}
                    />
                    <Button
                        variant="firstVariant"
                        type="submit"
                        fullWidth
                    >
                        Envoyer
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default Contact