import {useEffect, useState} from "react";

const useDomain = () => {
    const [subdomain, setSubdomain] = useState<string | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState(true);

    const processDomainLogic = () => {
        const subdomains: string[] = window.location.host.split('.');
        const subdomain = subdomains.length > 0 ? subdomains[0] : undefined;

        if (subdomain !== 'looty') {
            setSubdomain(subdomain);
        }

        setIsProcessing(false)
    }

    useEffect(() => {
        processDomainLogic();
    }, [subdomain]);

    return {subdomain, isProcessing};

}

export default useDomain;