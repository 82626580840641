import axios from '../api/axios';
import useAuth from './useAuth';
import {AuthenticationResponse} from "./useLogin";

const useRefreshToken = () => {
    const {setAuth} = useAuth();

    const refresh = async () => {

        const response = await axios.post<AuthenticationResponse>(
            '/auth/refresh', {}, {withCredentials: true}
        );
        setAuth(prev => {
            const authenticationResponse: AuthenticationResponse = response.data;
            return {
                ...prev,
                token: authenticationResponse.token,
                societyId: authenticationResponse.societyId
            }
        });
        return response.data.token;
    }
    return refresh;
};

export default useRefreshToken;