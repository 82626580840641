import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./context/AuthProvider";
import {SocietyProvider} from "./context/SocietyProvider";
import {BasketProvider} from "./context/BasketProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

declare module '@mui/material/styles' {
    interface Components {
        [key: string]: any
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        firstVariant: true;
        secondVariant: true;
        thirdVariant: true;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        firstVariant: true;
        secondVariant: true;
        thirdVariant: true;
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonOwnProps {
        variant?: "firstVariant" | "secondVariant";
    }
}

declare module "@mui/material/TextField" {
    interface TextfieldPropsVariantOverrides {
        basic: true;
    }
}

const theme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    // use the variant name defined earlier
                    props: {variant: "firstVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: "#B0754D",
                        textTransform: "none"
                    },
                },
                {
                    // use the variant name defined earlier
                    props: {variant: "secondVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: "#8C705E",
                        textTransform: "none"
                    },
                },
                {
                    // use the variant name defined earlier
                    props: {variant: "thirdVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: "#D6B59C",
                        textTransform: "none"
                    },
                },
            ]
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    '& .MuiFilledInput-root': {
                        backgroundColor: '#ffffff'
                    },
                    "& .MuiFilledInput-root:hover": {
                        backgroundColor: 'secondary.main'
                    },
                    "& .MuiFilledInput-root.Mui-focused": {
                        backgroundColor: 'secondary.main'
                    },
                    "& label.Mui-focused": {
                        color: "#B0754D"
                    },
                    // focused color for input with variant='standard'
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#B0754D"
                    },
                    // focused color for input with variant='filled'
                    "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#B0754D"
                    },
                    // focused color for input with variant='outlined'
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#B0754D"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#543B26",
                            borderWidth: "1px",
                        },
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff"
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    // use the variant name defined earlier
                    props: {variant: "firstVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#000000",
                        display: 'block',
                        fontFamily: 'insignia',
                    },
                },
                {
                    // ue the variant name defined earlier
                    props: {variant: "secondVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#B0754D",
                        display: 'block',
                        fontFamily: 'insignia',
                    },
                },
                {
                    // ue the variant name defined earlier
                    props: {variant: "thirdVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#543B26",
                        display: 'block',
                        fontFamily: 'insignia',
                    },
                },
            ],
        },
        MuiIconButton: {
            variants: [
                {
                    // use the variant name defined earlier
                    props: {variant: "firstVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#B0754D",
                        backgroundColor: "#ffffff"
                    },
                },
            ]
        }
    },
});

root.render(
    <BrowserRouter>
        <ScrollToTop/>
        <SocietyProvider>
            <BasketProvider>
                <AuthProvider>
                    <ThemeProvider theme={theme}>
                        <App/>
                    </ThemeProvider>
                </AuthProvider>
            </BasketProvider>
        </SocietyProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
