import React from "react";

const Unauthorized = () => {

    return (
        <div>
            <p>Accès non autorisé</p>
        </div>
    );
}

export default Unauthorized