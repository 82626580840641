import React from "react";
import styles from "./About.module.css";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";

const About = () => {

    const { aboutSection } = useSectionsRefs();

    const navigate = useNavigate();

    return (
        <div className={styles.container} ref={aboutSection}>
            <div className={styles.content}>
                <h2>À PROPOS</h2>
                <p>
                    Looty a été fondé en 2024 afin de répondre aux besoins des petites sociétés souhaitant, entre autres,
                    vendre leurs produits en lignes et organiser des manifestations. Les trois fondateurs ont su mettre en
                    commun leur expertise respective dans la gestion de sociétés, l’organisation d’événements et le
                    développement informatique pour vous proposer une plateforme aussi simple d’utilisation que possible et
                    qui vous permette de faire connaître votre activité au plus grand nombre. Le concept vous intéresse ?
                    Alors n’attendez plus et enregistrez votre société en 2 minutes.
                </p>

                <Button
                    variant="secondVariant"
                    onClick={() => navigate('/info')}
                >
                    Inscrire ma société
                </Button>

            </div>
        </div>
    );
}

export default About