import React from "react";
import useDataFetch from "../../../../hooks/useDataFetch";
import useSociety from "../../../../hooks/useSociety";

export interface ProductDTO {
    id?: number;
    name?: string;
    price?: number;
    visible?: boolean;
}

const SocietyAdminArticlesList = () => {

    const {society} = useSociety();

    const {data} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, true, true);

    return (
        <div style={{border: '1px solid black'}}>
            <h3>Articles</h3>
            <div>
                <h4>List des articles existants</h4>
                <ul>
                    {data && data.map((product: ProductDTO, index: number) => (
                        <li key={index}>
                            <img style={{width: '200px'}} src={`https://looty.ch:8443/api/v1/societies/${society.id}/products/${product.id}/image`} alt="product"/>
                            Nom: {product.name} <br></br>
                            Prix: {product.price}CHF
                            Publié: {product.visible ? "Oui" : "Non"}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default SocietyAdminArticlesList