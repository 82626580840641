import React from "react";
import styles from "./LaunchRegister.module.css";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";

const LaunchRegister = () => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Typography
                    sx={{
                        color: '#ffffff',
                }}
                    variant={"firstVariant"}
                    fontSize={45}
                >
                    Créez un compte gratuitement en moins de 5 minutes
                </Typography>

                <Typography
                    sx={{
                        color: '#ffffff',
                        mt: 4
                    }}
                    variant={"firstVariant"}
                    fontSize={25}
                    fontWeight={300}
                >
                    La validation de votre compte sera effectuée en moins de 24 heures et vous pourrez ensuite directement commencer vos ventes
                </Typography>

                <Button
                    variant="firstVariant"
                    fullWidth
                    sx={{
                        minWidth: 200,
                        maxWidth: 400,
                        height: 60,
                        fontSize: 23,
                        mt: 4
                    }}
                >
                    Démarrer l'inscription
                </Button>
            </div>
        </div>
    );
}

export default LaunchRegister